var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "authWrapper"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row whiteBG-wrap"
  }, [_vm._m(0), _c('div', {
    staticClass: "col-lg-6 col-md-6"
  }, [_c('div', {
    staticClass: "auth-form-wrap"
  }, [_c('div', {
    staticClass: "auth-form"
  }, [_c('h2', [_vm._v("Enter OTP")]), _c('div', {
    staticClass: "otp-form mb-5"
  }, [_c('v-otp-input', {
    ref: "otpInput",
    attrs: {
      "input-classes": "otp-input",
      "separator": "",
      "num-inputs": 4,
      "should-auto-focus": true,
      "is-input-num": true
    },
    on: {
      "on-change": _vm.handleOnChange,
      "on-complete": _vm.handleOnComplete
    }
  }), _c('div', {
    staticClass: "otp-error"
  }, [_vm.apiErrors ? _c('error-text', {
    attrs: {
      "errors": _vm.apiErrors,
      "field": "otp"
    }
  }) : _vm._e()], 1)], 1), _c('button', {
    class: _vm.isOtpComplete ? 'submit-btn' : 'disable-submit-btn',
    attrs: {
      "type": "submit",
      "disabled": _vm.isDownload || !_vm.isOtpComplete
    },
    on: {
      "click": _vm.downloadFile
    }
  }, [_vm.formLoader ? _c('b-spinner', {
    attrs: {
      "small": ""
    }
  }) : _vm._e(), _vm._v(" " + _vm._s(_vm.isDownload ? "Downloading...." : "Download") + " ")], 1)])])])])])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-lg-6 col-md-6 no-padding"
  }, [_c('div', {
    staticClass: "left-bg-wrapper text-center"
  }, [_c('h1', [_vm._v("Hello")]), _c('p', [_vm._v("Please enter otp to download your file.")])])]);
}]

export { render, staticRenderFns }