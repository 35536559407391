<template>
  <div class="authWrapper">
    <div class="container">
      <div class="row whiteBG-wrap">
        <div class="col-lg-6 col-md-6 no-padding">
          <div class="left-bg-wrapper text-center">
            <h1>Hello</h1>
            <p>Please enter otp to download your file.</p>
          </div>
        </div>
        <div class="col-lg-6 col-md-6">
          <div class="auth-form-wrap">
            <div class="auth-form">
              <h2>Enter OTP</h2>
              <div class="otp-form mb-5">
                <v-otp-input
                  ref="otpInput"
                  input-classes="otp-input"
                  separator=""
                  :num-inputs="4"
                  :should-auto-focus="true"
                  :is-input-num="true"
                  @on-change="handleOnChange"
                  @on-complete="handleOnComplete"
                />
                <div class="otp-error">
                  <error-text
                    v-if="apiErrors"
                    :errors="apiErrors"
                    field="otp"
                  />
                </div>
              </div>
              <button
                @click="downloadFile"
                type="submit"
                :class="isOtpComplete ? 'submit-btn' : 'disable-submit-btn'"
                :disabled="isDownload || !isOtpComplete"
              >
                <b-spinner v-if="formLoader" small></b-spinner>
                {{ isDownload ? "Downloading...." : "Download" }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
let axiosInstance = axios.create({ headers: "" });
axiosInstance.defaults.baseURL = process.env.VUE_APP_BASE_API_URL;
export default {
  name: "FileShare",
  data: () => ({
    file: {},
    otp: "",
    formLoader: false,
    errorClass: "",
    isDownload: false,
    isOtpComplete: false,
    apiErrors: {},
  }),
  methods: {
    async downloadFile() {
      const url = `api.access/v2/file-share/download`;
      await axiosInstance
        .post(url, { otp: this.otp })
        .then((res) => {
          this.handleClearInput();
          this.$notify("Downloading file...");
          this.$nextTick(() => {
            window.open(res.data.data.link, "_blank");
          });
        })
        .catch((err) => {
          if (err.response.status == 422) {
            this.apiErrors = err.response.data.errors;
            setTimeout(() => {
              this.apiErrors = null;
            }, 5000);
          } else {
            this.$notify(err.response.data.message, "error");
          }
        });
    },
    handleOnComplete(value) {
      this.isOtpComplete = true;
      this.otp = value;
    },
    handleOnChange(value) {
      this.otp = value;
    },
    handleClearInput() {
      this.otp = "";
      this.$refs.otpInput.clearInput();
    },
  },
  created() {
    this.getData();
  },
};
</script>
<style src="@/assets/css/authen.css"></style>
<style>
.highlight_otp {
  border: 2px solid red !important;
}

button.disable-submit-btn {
  width: 100%;
  border: 0;
  background: rgb(172, 202, 209);
  color: #fff;
  padding: 13px;
  font-weight: 600;
  border-radius: 6px;
  outline: none;
  transition: all ease 0.6s;
}

.otp-error {
  padding-left: 12px;
}
</style>
